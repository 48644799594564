import React from "react";
import { Page } from "components";

export function About() {

    return (

        <Page />

    );

}
